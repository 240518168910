import React from 'react';
import log from 'loglevel';
import * as yup from 'yup';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import SendIcon from '@mui/icons-material/Send';
import Stack from '@mui/material/Stack';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import TextField from '@mui/material/TextField';

import { useDataStore } from '../../comp/DataStore';
import { SettingsKey, SettingsModel } from '../../comp/DataStore/Settings';
import ControlBar from '../../comp/Layout/Paperbase/ControlBar';
import ProposalTypeForm from './ProposalTypeForm';
import settings from '../../settings';

import type { ProposalTypeSettings } from './ProposalTypeForm';
import { ProposalType } from '../../comp/DataStore/Proposals';

function settingsSchema() {
  return yup
    .object()
    .noUnknown()
    .shape({
      type: yup
        .string()
        .required()
        .oneOf(Object.keys(ProposalType))
        .label('Proposal Type'),
      fromName: yup.string().optional().nullable().label('From Name'),
      fromEmail: yup
        .string()
        .email()
        .optional()
        .nullable()
        .label('From Email Address'),
      emailSubject: yup.string().required().label('Email Subject'),
      emailBody: yup.object().required().label('Email Body'),
      cc: yup.array().ensure().optional().of(yup.string().email()),
      bcc: yup.array().ensure().optional().of(yup.string().email()),
      gDocTemplate: yup.string().required().label('Google Document ID'),
      gFolderId: yup
        .string()
        .optional()
        .nullable()
        .label('Google Drive Folder ID'),
    });
}

type ProposalSettingsData = {
  timestamp: number;
  acceptButtonUrl: string;
  proposalType: ProposalTypeSettings[];
};

function ProposalSettings() {
  const datastore = useDataStore();

  const initSettings = datastore.settings.getState();
  const [refresh, setRefresh] = React.useState(false);
  const [settingsState, setSettingsState] = React.useState<any>(initSettings);
  const [expanded, setExpanded] = React.useState<ProposalType | undefined>();
  const [loading, setLoading] = React.useState(false);
  const [errors, setErrors] = React.useState<any>({});

  const defSettings: ProposalSettingsData = {
    timestamp: Date.now(),
    acceptButtonUrl: '',
    proposalType: [],
  };
  const [proposalSettings, setProposalSettings] =
    React.useState<ProposalSettingsData>(defSettings);

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setLoading(true);

    let hasError = false;

    try {
      const acceptUrlSchema = yup.string().required();
      acceptUrlSchema.validateSync(proposalSettings.acceptButtonUrl, {
        abortEarly: false,
      });
    } catch (err) {
      log.error(err);
      hasError = true;
      setErrors((curr: any) => ({
        ...curr,
        acceptButtonUrl: err,
      }));
    }

    proposalSettings.proposalType.forEach((data) => {
      try {
        settingsSchema().validateSync(data, { abortEarly: false });
        setErrors((curr: any) => ({
          ...curr,
          [data.type]: undefined,
        }));
      } catch (err) {
        log.error(data.type, ':', err);
        hasError = true;
        setErrors((curr: any) => ({
          ...curr,
          [data.type]: err,
        }));
      }
    });

    if (hasError) {
      log.error({ errors });
      setLoading(false);
      return;
    }

    const record: SettingsModel = {
      key: SettingsKey.PROPOSALS,
      data: {
        ...proposalSettings,
        timestamp: Date.now(),
      },
    };
    await datastore.settings.update([record]).finally(() => {
      setLoading(false);
    });
  };

  const handleExpand = (val: ProposalType) => () => {
    setExpanded((curr) => (curr === val ? undefined : val));
  };

  const handleProposalTypeUpdate = (data: any) => {
    settingsSchema()
      .validate(data, { abortEarly: false })
      .then(() => {
        setErrors((curr: any) => ({
          ...curr,
          [data.type]: undefined,
        }));
      })
      .catch((err) => {
        setErrors((curr: any) => ({
          ...curr,
          [data.type]: err,
        }));
      });
    log.debug({ type: data.type, data });

    setProposalSettings((curr) => {
      const newSettings = { ...curr };
      let existing = curr.proposalType.find((x) => x.type === data.type);
      if (!existing) {
        existing = data as ProposalTypeSettings;
        newSettings.proposalType.push(existing);
      } else {
        Object.assign(existing, data);
      }
      return newSettings;
    });
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value, type, checked } = event.target;
    let inputValue = type === 'checkbox' ? checked : value;

    setProposalSettings((curr) => ({
      ...curr,
      [name]: inputValue,
    }));

    setErrors((curr: any) => ({
      ...curr,
      [name]: undefined,
    }));
  };

  // const copyToClipboard = (str: string) => () => {
  //   navigator.clipboard.writeText(str);
  // };

  React.useEffect(() => {
    const p = settingsState.records.find(
      (x: SettingsModel) => x.key === SettingsKey.PROPOSALS
    );
    if (p && p.data) {
      setProposalSettings(p.data);
    }
  }, [settingsState]);

  const proposalTypes = React.useMemo(() => {
    const list = settings.proposalType
      .map((ptype) => {
        const initialData: Partial<ProposalTypeSettings> =
          proposalSettings.proposalType.find((x) => x.type === ptype.value) || {
            type: ptype.value,
          };

        const error = errors[ptype.value];
        const errorBorder = Boolean(error) ? '#D32F2F' : '#C0C0C0';
        return (
          <Accordion
            key={ptype.value}
            expanded={expanded === ptype.value}
            onChange={handleExpand(ptype.value)}
            elevation={0}
            sx={{ border: `1px solid ${errorBorder}`, mb: '5px' }} //
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
            // sx={{ backgroundColor: 'greenyellow'}}
            >
              <Typography>{ptype.label}</Typography>
              <Box sx={{ display: 'inline', width: '10px' }}></Box>
              <Typography sx={{ color: 'text.secondary' }}>
                {ptype.description}
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <ProposalTypeForm
                loading={loading}
                initialData={initialData}
                onChange={handleProposalTypeUpdate}
                error={error}
                timestamp={proposalSettings.timestamp}
              />
            </AccordionDetails>
          </Accordion>
        );
      });
    return list;
  }, [expanded, errors, proposalSettings, loading]);

  React.useEffect(() => {
    const settingsId = datastore.settings.subscribe(setSettingsState);
    return () => {
      datastore.settings.unsubscribe(settingsId);
    };
  }, [datastore]);

  // Load latest settings
  React.useEffect(() => {
    if (refresh) return;
    setRefresh(true);
    if (loading) return;
    setLoading(true);
    datastore.settings
      .getKey(SettingsKey.PROPOSALS)
      .then((result: any) => {
        if (result) {
          setProposalSettings({
            ...result.data,
            timestamp: Date.now(),
          });
        }
      })
      .finally(() => {
        setLoading(false);
      });
  }, [refresh, datastore.settings, loading]);

  React.useEffect(() => {
    const handleBeforeUnload = (event: any) => {
      event.preventDefault();
      event.returnValue = ''; // Needed for Chrome
    };

    window.addEventListener('beforeunload', handleBeforeUnload);
    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, []);

  return (
    <>
      <Box component='form' onSubmit={handleSubmit} noValidate>
        <Grid container justifyContent='center' spacing={2}>
          <Grid item xs={12}>
            <TextField
              key={`accept-url`}
              required
              fullWidth
              id='acceptButtonUrl'
              label='Accept Button URL'
              name='acceptButtonUrl'
              disabled={loading}
              value={proposalSettings.acceptButtonUrl || ''}
              InputLabelProps={{ shrink: true }}
              onChange={handleChange}
              error={errors.acceptButtonUrl}
              helperText='Can be a templated URL similar to emails.'
            />
          </Grid>
          <Grid item xs={12}>
            {proposalTypes}
          </Grid>
        </Grid>
        <ControlBar>
          <Grid container spacing={2} alignItems='center'>
            <Grid item xs={12}>
              <Stack direction='row' spacing={3} justifyContent='right'>
                <Button
                  type='submit'
                  color='primary'
                  variant='contained'
                  disabled={loading}
                  endIcon={<SendIcon />}
                >
                  Update
                </Button>
              </Stack>
            </Grid>
          </Grid>
        </ControlBar>
      </Box>
    </>
  );
}

export default ProposalSettings;
export type { ProposalSettingsData };
