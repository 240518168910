import React from 'react';
// import log from 'loglevel';

import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import CommentIcon from '@mui/icons-material/Comment';

import DisplayBox from './DisplayBox';
import SubmitForm from './SubmitForm';

import { useDataStore } from '../DataStore';

type ModalBoxProps = {
  inputReferences: any,
  outputReferences: any,
  filter?: (r: any) => boolean,
  children?: React.ReactNode,
};

function ModalBox(props: ModalBoxProps) {
  const { inputReferences, outputReferences, filter, children } = props;

  const datastore = useDataStore();
  const [state, setState] = React.useState({
    dialog: false,
    scrollPosition: 0,
    sticky: true,
  });
  const contentRef = React.useRef<any>({});

  const updateState = (key: string, value?: any) => () => {
    setState((curr: any) => ({
      ...curr,
      [key]: value,
    }));
  };

  const handleDialog = (value: boolean) => () => {
    if (value && contentRef.current) {
      const elem = contentRef.current;
      elem.scrollTop = elem.scrollHeight - elem.offsetHeight;
    }
    updateState('dialog', value)();
  }

  React.useEffect(() => {
    if (!state.dialog) return;
    if (!contentRef.current) return;
    if (!state.sticky) return;
    if (state.scrollPosition !== 1) {
      updateState('scrollPosition', 1);
    }
    // set to bottom
    const elem = contentRef.current;
    elem.scrollTop = elem.scrollHeight - elem.offsetHeight;
  }, [state]);

  React.useEffect(() => {
    datastore.messages.addReference(outputReferences);
    return () => {
      datastore.messages.deleteReference(outputReferences);
    }
  });

  return (
    <>
      {children ? (
        <Box onClick={handleDialog(true)}>
          {children}
        </Box>
      ) : (
        <IconButton onClick={handleDialog(true)}>
          <CommentIcon />
        </IconButton>
      )}
      <Dialog
        open={state.dialog}
        onClose={handleDialog(false)}
        fullWidth
        maxWidth="xs"
        sx={(theme) => {
          return {
            zIndex: theme.zIndex.appBar + 10,
          };
        }}
        scroll="paper"
      >
        <DialogTitle sx={{ bgcolor: 'primary.light', p: '10px 15px' }}>
          <Stack
            direction="row"
            spacing={1}
            alignItems="center"
            justifyContent="space-between"
          >
            <Typography variant="h6">Proposal Activity</Typography>
            <Stack direction="row" spacing={1} justifyContent="flex-end">
              <IconButton onClick={handleDialog(false)}>
                <CloseIcon />
              </IconButton>
            </Stack>
          </Stack>
        </DialogTitle>
        <DialogContent
          ref={contentRef}
          sx={{
            height: '90vh',
            padding: '0px',
          }}
        >
          <DisplayBox
            references={outputReferences}
            filter={filter}
            onChange={() => {
              // set to bottom
              const elem = contentRef.current;
              elem.scrollTop = elem.scrollHeight - elem.offsetHeight;
            }}
          />
        </DialogContent>
        <DialogActions>
          <SubmitForm references={inputReferences} />
        </DialogActions>
      </Dialog>
    </>
  );
}

export default ModalBox;
