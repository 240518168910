import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';
import log from 'loglevel';
import * as dateFns from 'date-fns';
// import ReactGA from "react-ga4";

import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Chip from '@mui/material/Chip';
import Button from '@mui/material/Button';
import MenuItem from '@mui/material/MenuItem';
import TextField from '@mui/material/TextField';
import Select from '@mui/material/Select';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Stack from '@mui/material/Stack';
import CircularProgress from '@mui/material/CircularProgress';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import LoadingButton from '@mui/lab/LoadingButton';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import EditIcon from '@mui/icons-material/Edit';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import DownloadIcon from '@mui/icons-material/Download';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Alert from '@mui/material/Alert';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionActions from '@mui/material/AccordionActions';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';

import Paperbase from '../../comp/Layout/Paperbase/Paperbase';
import ControlBar from '../../comp/Layout/Paperbase/ControlBar';
// import GmapImage from '../../comp/GoogleMap/GmapImage';
import ShowMap from './ShowMap';
import MessageButton from '../../comp/Messages/Button';
import ViewAppointments from './ViewAppointments';
import ViewNotes from './ViewNotes';
import ViewAttachments from './ViewAttachments';
import SendProposalModal from './SendProposalModal';
import SalesPersonAutocomplete from '../../comp/Autocomplete/SalesPerson';
import { DataContext, useDataStore } from '../../comp/DataStore';
import {
  ProposalRecord,
  ProposalStatus,
  ProposalViewRecord,
} from '../../comp/DataStore/Proposals';
import { MessageType } from '../../comp/DataStore/Messages';
// import { SettingsKey } from '../../comp/DataStore/Settings';
// import { deltaToHtml } from '../../comp/Quill';
// import { createPdf, downloadDoc, sendEmail } from '../../api/irrigationApi';
import settings from '../../settings';
import { Proposal, newProposal } from './getProposalModel';
import usePdfFn from './usePdfFn';
import { ViewElectronicProposal } from './ViewElectronicProposal';

async function addChildProposal(props: {
  proposal?: ProposalViewRecord;
  datastore: DataContext;
  year: number;
}) {
  const { proposal, datastore, year } = props;
  if (!proposal) {
    throw new Error('Missing proposal parameter');
  }

  // payload
  const payload: ProposalRecord = {
    id: uuidv4(),
    parent_id: proposal.id,
    proposal_type: proposal.proposal_type,
    prospect_id: proposal.prospect_id,
    assigned_to: proposal.assigned_to,
    map: proposal.map,
    status: proposal.status,
    notes: proposal.notes,
    address: proposal.address,
    prepared_by: proposal.prepared_by,
    services: proposal.services,
    file: undefined,
    year,
    accepted_at: proposal.accepted_at,
    created_at: new Date().toISOString(),
    updated_at: new Date().toISOString(),
  };
  log.debug('add child proposal', payload);
  await datastore.proposals.create(payload);
  return payload;
}

interface ViewProposalProps {
  id?: any;
}

function ViewProposal(props: ViewProposalProps) {
  const datastore: any = useDataStore();
  const navigate = useNavigate();
  const { createPdf, downloadPdf, sendProposals } = usePdfFn();

  // const { REACT_APP_IRRIGATION_BASE_URL: baseUrl } = process.env;

  let { id } = useParams();
  if (props.id) id = props.id;

  // const authState = datastore.auth.getState();

  const dataRef = React.useRef({ runOnce: false });
  const [loading, setLoading] = React.useState(false);
  const [assignedTo, setAssignedTo] = React.useState<any>({});
  const [data, setData] = React.useState<Proposal>();
  const [chData, setChData] = React.useState<any[]>([]);
  const [map, setMap] = React.useState<any>({});
  const [loadingPdf, setLoadingPdf] = React.useState(false);
  const [status, setStatus] = React.useState<ProposalStatus>(
    ProposalStatus.INACTIVE
  );
  const [changeYr, setChangeYr] = React.useState<any>({
    dialog: false,
    data: {},
  });

  const proposals = React.useMemo(() => {
    const list = [data, ...chData];
    return list.map((x) => x || {}).sort((a, b) => a.year - b.year);
  }, [data, chData]);

  // const transformData = getTransformer({ datastore });

  const onSelectAssignedTo = (e: any, value: any, reason: any) => {
    if (reason === 'selectOption') {
      log.debug('assigned to', value);
      setAssignedTo(value);
      datastore.proposals.update(
        { id, assigned_to: value.email },
        { pick: ['id', 'assigned_to'] }
      );
    }
  };

  const onClickEdit = () => {
    navigate(`/proposals/${id}/edit`);
  };

  const generatePdf = React.useCallback(
    (p: Proposal) => async () => {
      setLoading(true);
      setLoadingPdf(true);

      const promise = createPdf(p)
        .then(downloadPdf)
        .catch((err) => {
          console.error(err);
        })
        .finally(() => {
          setLoading(false);
          setLoadingPdf(false);
        });

      return promise;
    },
    [createPdf, downloadPdf]
  );

  const setChangeYrDialog = (val: boolean, data?: any) => () => {
    setChangeYr((curr: any) => ({ dialog: val, data: data || {} }));
  };

  const onChangeYear = (proposal: any, year: number) => () => {
    setChangeYrDialog(false)(); // close dialog
    // update records
    datastore.proposals
      .update(
        { id: proposal.id, year, file: null },
        { pick: ['id', 'year', 'file'] }
      )
      .then(() => {
        refresh();
      });
    // update data
    if (data?.id === proposal.id) {
      setData((curr: any) => ({ ...curr, year }));
    } else {
      // update child data
      setChData((curr: any[]) =>
        curr.map((p) => ({
          ...p,
          year: p.id === proposal.id ? year : p.year,
        }))
      );
    }
  };

  const deleteChildProposal = React.useCallback(
    (p: any) => {
      return async () => {
        setLoading(true);
        datastore.proposals
          .deleteChild({
            id: p.records.proposal.id,
            parent_id: p.records.proposal.parent_id,
          })
          .finally(() => {
            dataRef.current.runOnce = false;
            setLoading(false);
          });
      };
    },
    [datastore.proposals]
  );

  const onEditServices = React.useCallback(
    (p: any) => {
      return async () => {
        navigate(`/proposals/${p.id}/edit-services`);
      };
    },
    [navigate]
  );

  const onChangeStatus = async (e: any) => {
    const { value } = e.target;
    let acceptedAt: string | null = null;
    if (
      status !== ProposalStatus.ACCEPTED &&
      value === ProposalStatus.ACCEPTED
    ) {
      acceptedAt = new Date().toISOString();
    }

    setStatus(value);
    await datastore.proposals.update(
      { id, status: value, accepted_at: acceptedAt },
      { pick: ['id', 'status', 'updated_at', 'accepted_at'] }
    );
  };

  const addAppointment = () => {
    const p = {
      prospect_id: data?.records.proposal.prospect_id,
      proposal_id: id,
    };
    const encoded = encodeURIComponent(JSON.stringify(p));
    navigate(`/appointments?p=${encoded}`);
  };

  const updateNotes = (notes: any) => {
    if (!notes) return;
    // update records
    datastore.proposals.update(
      { id: data?.id, notes },
      { pick: ['id', 'notes'] }
    );
    // update data
    setData((curr: any) => ({ ...curr, notes }));
    setChData((curr: any[]) =>
      curr.map((currCh: any) => ({ ...currCh, notes }))
    );
  };

  const updateAttachments = (attachments: Proposal['attachments']) => {
    if (!attachments) return;
    // update records
    datastore.proposals.update(
      { id: data?.id, attachments },
      { pick: ['id', 'attachments'] }
    );
    // update data
    setData((curr: any) => ({ ...curr, attachments }));
  };

  const onClickAddProposal = async () => {
    const maxYear: number = proposals.reduce((prev, curr) => {
      return curr.year > prev ? curr.year : prev;
    }, 0);

    setLoading(true);
    await addChildProposal({
      proposal: data?.records?.proposal,
      year: maxYear + 1,
      datastore,
    });

    dataRef.current.runOnce = false;
    setLoading(false);
  };

  const handleDelete = async () => {
    setLoading(true);
    if (!id) return;
    log.debug('delete', id);
    datastore.proposals.delete({ id });
    setLoading(false);
    navigate('/proposals');
  };

  const proposalNodes = React.useMemo(
    () =>
      proposals.map((p: any, idx: number) => {
        const yearNow = new Date().getFullYear();
        const yearData: any = {
          proposal: p,
          year: p.year,
          options: [],
        };
        for (var i = 0; i < 3; i += 1) {
          yearData.options.push(yearNow + i);
        }

        const Title = () => {
          return (
            <>
              <Stack spacing={1} direction='row'>
                <Typography variant='h5'>{`${p.year || ''} Services`}</Typography>
                <IconButton
                  size='small'
                  title='Change Year'
                  onClick={setChangeYrDialog(true, yearData)}
                >
                  <EditIcon />
                </IconButton>
              </Stack>
            </>
          );
        };

        return (
          <Grid item xs={12} key={p.id || uuidv4()}>
            <Card sx={{ p: '5px', border: '1px solid #dadada' }}>
              <CardHeader
                title={<Title />}
                action={
                  <>
                    <Stack
                      spacing={1}
                      direction='row'
                      sx={{ display: { xs: 'none', md: 'block' } }}
                    >
                      <Button
                        disabled={loading}
                        variant='outlined'
                        startIcon={<EditIcon />}
                        onClick={onEditServices(p)}
                      >
                        Edit
                      </Button>
                      <LoadingButton
                        disabled={loading || loadingPdf}
                        loading={loadingPdf}
                        loadingPosition='start'
                        startIcon={<DownloadIcon />}
                        variant='outlined'
                        onClick={generatePdf(p)}
                      >
                        Preview PDF
                      </LoadingButton>
                      {idx !== 0 ? (
                        <Button
                          disabled={loading}
                          variant='outlined'
                          startIcon={<DeleteForeverIcon />}
                          color='error'
                          onClick={deleteChildProposal(p)}
                        >
                          Delete
                        </Button>
                      ) : (
                        <></>
                      )}
                    </Stack>
                    <Stack
                      direction='row'
                      sx={{ display: { xs: 'block', md: 'none' } }}
                    >
                      <IconButton
                        disabled={loading}
                        onClick={onEditServices(p)}
                      >
                        <EditIcon />
                      </IconButton>
                      <IconButton
                        disabled={loading || loadingPdf}
                        onClick={generatePdf(p)}
                      >
                        <DownloadIcon />
                      </IconButton>
                      {idx !== 0 ? (
                        <IconButton
                          disabled={loading}
                          color='error'
                          onClick={deleteChildProposal(p)}
                        >
                          <DeleteForeverIcon />
                        </IconButton>
                      ) : (
                        <></>
                      )}
                    </Stack>
                  </>
                }
                sx={{ p: '10px' }}
              />
              <CardContent sx={{ p: '15px', pt: '5px' }}>
                <Grid container>
                  {p.services?.records.map((s: any) => {
                    return (
                      <React.Fragment key={s.id || uuidv4()}>
                        <Grid item xs={9}>
                          &gt; {s.name}
                        </Grid>
                        <Grid item xs={3} textAlign='right'>
                          {s.price || '-'}
                        </Grid>
                      </React.Fragment>
                    );
                  })}
                  <Grid item xs={6}></Grid>
                  <Grid item xs={6}>
                    <Divider sx={{ m: '5px' }} />
                  </Grid>
                  <Grid item xs={9} textAlign='right'>
                    Sub Total:
                  </Grid>
                  <Grid item xs={3} textAlign='right'>
                    {p.services?.subtotal}
                  </Grid>
                  <Grid item xs={9} textAlign='right'>
                    Tax:
                  </Grid>
                  <Grid item xs={3} textAlign='right'>
                    {p.services?.tax}
                  </Grid>
                  <Grid item xs={9} textAlign='right'>
                    Total:
                  </Grid>
                  <Grid item xs={3} textAlign='right'>
                    {p.services?.total}
                  </Grid>
                </Grid>
              </CardContent>
              {p.services?.hasPriceDiff ? (
                <CardActions>
                  <Grid container>
                    <Grid item xs={12}>
                      <Alert severity='warning'>
                        This proposal has price difference from the current
                        price. Editing it will update the price.
                      </Alert>
                    </Grid>
                  </Grid>
                </CardActions>
              ) : (
                <></>
              )}
            </Card>
          </Grid>
        );
      }),
    [
      proposals,
      loading,
      loadingPdf,
      generatePdf,
      deleteChildProposal,
      onEditServices,
    ]
  );

  const refresh = React.useCallback(async () => {
    if (loading) return;
    setLoading(true);
    return (
      newProposal({ datastore, id: id || '' })
        .then((d: any) => {
          if (d.records.proposal.parent_id) {
            navigate(`/proposals/${d.records.proposal.parent_id}`);
          }
          log.debug('data', d);
          setData(d);
          setMap(d.records.proposal.map);
          setAssignedTo(d.records.assigned_to);
          setStatus(d.records.proposal.status);
        })
        // children
        .then(() => datastore.proposals.getChildren({ id }))
        .then((children: any[]) => {
          log.debug('getting child proposals', children);
          return Promise.all(
            children.map((p: any) => newProposal({ datastore, id: p.id }))
          );
        })
        .then((children: any[]) => {
          log.debug('child proposals', children);
          setChData(children);
        })
        .finally(() => setLoading(false))
    );
  }, [datastore, id, navigate, loading]);

  // const formatTemplateName = (templateName:string) => {
  //   if (templateName === 'IRRIGATION') {
  //     return 'Irrigation';
  //   } else if (templateName === 'LAWN') {
  //     return 'Lawn';
  //   } else if (templateName === 'LAWN & IRRIGATION') {
  //     return 'Lawn & Irrigation';
  //   } else {
  //     return templateName;
  //   }
  // }

  React.useEffect(() => {
    // bug: id not loading when notification is clicked
    if (!loading && data?.id !== id) {
      dataRef.current.runOnce = false;
    }
    if (dataRef.current.runOnce) return;
    if (loading) return;
    dataRef.current.runOnce = true;
    // setLoading(true);
    // parent
    refresh();
    // .finally(() => {
    //   setLoading(false);
    // });
  }, [refresh, loading, id, datastore, data?.id, navigate]);

  React.useEffect(() => {
    const proposalsId = datastore.proposals.subscribe(() => {
      refresh();
    });
    return () => {
      datastore.proposals.unsubscribe(proposalsId);
    };
  }, [datastore, refresh]);

  return (
    <Paperbase title='View Proposal' bottomSpacer='100px'>
      <Grid container spacing={2}>
        <Grid item xs={8}>
          <Grid container spacing={2}>
            {!data?.records?.proposal?.deleted_at ? (
              <></>
            ) : (
              <Grid item xs={12}>
                <Alert severity='error' sx={{ border: '1px solid red' }}>
                  <Typography>
                    {'This proposal was deleted at '}
                    {dateFns.format(
                      dateFns.parseISO(data?.records.proposal.deleted_at),
                      'MMMM dd, yyyy'
                    )}
                  </Typography>
                </Alert>
              </Grid>
            )}
            <Grid item xs={6}>
              <Typography variant='body1'>
                <b>Prepared for:</b>
              </Typography>
              <List disablePadding dense>
                <ListItem key='full_name' sx={{ pt: '0', pb: '0', pr: '20px' }}>
                  {data?.prospect?.full_name}
                </ListItem>
                <ListItem key='address' sx={{ pt: '0', pb: '0', pr: '20px' }}>
                  <Box>
                    {data?.prospect?.address
                      ? String(data?.prospect?.address)
                        .split('\n')
                        .map((s) => <div key={s}>{s}</div>)
                      : ''}
                  </Box>
                </ListItem>
                <ListItem key='email' sx={{ pt: '0', pb: '0', pr: '20px' }}>
                  {data?.prospect?.email}
                </ListItem>
                <ListItem key='phone' sx={{ pt: '0', pb: '0', pr: '20px' }}>
                  {data?.prospect?.phone_1}
                </ListItem>
              </List>
            </Grid>
            <Grid item xs={6}>
              <Typography variant='body1'>
                <b>Prepared by:</b>
              </Typography>
              <List disablePadding dense>
                <ListItem
                  key='prepared_by'
                  sx={{ pt: '0', pb: '0', pr: '20px' }}
                >
                  {data?.prepared_by?.full_name}
                </ListItem>
                <ListItem
                  key='prepared_by_email'
                  sx={{ pt: '0', pb: '0', pr: '20px' }}
                >
                  {data?.prepared_by?.email}
                </ListItem>
                <ListItem
                  key='prepared_by_phone'
                  sx={{ pt: '0', pb: '0', pr: '20px' }}
                >
                  {data?.prepared_by?.phone}
                </ListItem>
              </List>
              <Typography variant='body1'>
                <b>Details:</b>
              </Typography>
              <List disablePadding dense>
                <ListItem
                  key='area'
                  sx={{ pt: '0', pb: '0', pr: '20px' }}
                  secondaryAction={data?.services?.total_area_sqft}
                >
                  Total Area:
                </ListItem>
              </List>
            </Grid>

            <Grid item xs={12}>
              <Grid container spacing={2}>
                {proposalNodes}
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Button
                variant='outlined'
                onClick={onClickAddProposal}
                disabled={loading}
              >
                Add Another Proposal
              </Button>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={4}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <SalesPersonAutocomplete
                renderInput={(p: any) => {
                  const { isLoading, ...textFieldParams } = p;
                  return (
                    <TextField
                      {...textFieldParams}
                      key={`assigned_to-${id}`}
                      required
                      fullWidth
                      id='assigned_to'
                      label='Sales Lead'
                      name='assigned_to'
                      InputProps={{
                        ...textFieldParams.InputProps,
                        endAdornment: (
                          <React.Fragment>
                            {isLoading ? (
                              <CircularProgress color='inherit' size={20} />
                            ) : null}
                            {textFieldParams.InputProps.endAdornment}
                          </React.Fragment>
                        ),
                      }}
                    />
                  );
                }}
                value={assignedTo}
                onChange={onSelectAssignedTo}
                onInputChange={onSelectAssignedTo}
                // optional: TODO
                options={[]}
                loading={loading}
                getOptionLabel={() => ''}
                isOptionEqualToValue={() => true}
              />
            </Grid>
            <Grid item xs={12}>
              <FormControl fullWidth>
                <InputLabel>Status</InputLabel>
                <Select value={status} label='Status' onChange={onChangeStatus}>
                  {settings.proposalStatus
                    .sort((a, b) => (a.order || 0) - (b.order || 0))
                    .map((x) => (
                      <MenuItem key={x.value} value={x.value}>
                        {x.label}
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <Accordion defaultExpanded>
                <AccordionSummary expandIcon={<ExpandMoreIcon />} id='map'>
                  <Typography>Map</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <ShowMap map={map} />
                  {/* {map.center ? <GmapImage {...map} /> : <></>} */}
                </AccordionDetails>
              </Accordion>
              <Accordion>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  id='appointments'
                >
                  <Typography>Appointments</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <ViewAppointments
                    records={data?.records?.appointments || []}
                  />
                </AccordionDetails>
                <AccordionActions>
                  <Button
                    onClick={addAppointment}
                    variant='outlined'
                    size='small'
                  >
                    <Typography>Open Calendar</Typography>
                  </Button>
                </AccordionActions>
              </Accordion>
              <Accordion>
                <AccordionSummary expandIcon={<ExpandMoreIcon />} id='notes'>
                  <Typography>Notes</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <ViewNotes notes={data?.notes} update={updateNotes} />
                </AccordionDetails>
              </Accordion>
              <Accordion>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  id='attachments'
                >
                  <Typography>{`Attachments (${(data?.attachments?.records || []).length
                    })`}</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <ViewAttachments
                    attachments={data?.attachments}
                    onChange={updateAttachments}
                  />
                </AccordionDetails>
              </Accordion>
            </Grid>
            <Grid item xs={12} textAlign='right'>
              <ViewElectronicProposal proposals={proposals} />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Dialog open={changeYr.dialog} onClose={setChangeYrDialog(false)}>
        <DialogTitle>Select Year</DialogTitle>
        <DialogContent>
          <Stack spacing={1} direction='row'>
            {(changeYr.data?.options || []).map((yr: number) => {
              return (
                <Button
                  key={yr}
                  variant={
                    String(changeYr.data?.year) === String(yr)
                      ? 'contained'
                      : 'outlined'
                  }
                  onClick={onChangeYear(changeYr.data?.proposal, yr)}
                >
                  {yr}
                </Button>
              );
            })}
          </Stack>
        </DialogContent>
      </Dialog>
      <ControlBar>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <Stack
              direction='row'
              spacing={3}
              justifyContent='left'
              alignItems='center'
            >
              <Typography variant='body1'>Template:</Typography>
              <Chip
                label={data?.type}
                variant='outlined'
                color='primary'
                sx={{
                  fontWeight: 600,
                  minWidth: 100,
                  maxWidth: 200,
                  textTransform: 'capitalize',
                }}
              />
            </Stack>
          </Grid>
          <Grid item xs={6}>
            <Stack direction='row' spacing={2} justifyContent='right'>
              <MessageButton
                outputReferences={{
                  proposal_id: data?.id,
                }}
                inputReferences={{
                  proposal_id: data?.id,
                  prospect_id: data?.prospect?.id,
                  assigned_to: data?.assigned_to?.email,
                }}
                filter={(r: any) =>
                  r.message_type !== MessageType.NOTIFICATION
                }
              >
                <Button variant='outlined'>Proposal Activity</Button>
              </MessageButton>
              {!data?.records?.proposal?.deleted_at ? (
                <>
                  <Button
                    disabled={loading}
                    variant='outlined'
                    startIcon={<DeleteForeverIcon />}
                    color='error'
                    onClick={handleDelete}
                  >
                    Delete Proposal
                  </Button>
                  <Button
                    disabled={loading}
                    variant='outlined'
                    onClick={onClickEdit}
                    startIcon={<EditOutlinedIcon />}
                  >
                    Edit
                  </Button>
                  <SendProposalModal proposals={proposals} />
                  {/* 
                  <Button
                    disabled={loading}
                    variant="contained"
                    startIcon={<MailIcon />}
                    onClick={sendAll}
                  >
                    Send
                  </Button> */}
                </>
              ) : (
                <>
                  <Button
                    disabled={loading}
                    variant='outlined'
                    onClick={() => navigate('/proposals')}
                  >
                    List Proposals
                  </Button>
                </>
              )}
            </Stack>
          </Grid>
        </Grid>
      </ControlBar>
    </Paperbase>
  );
}

export default ViewProposal;
