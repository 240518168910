import React from 'react';
import log from 'loglevel';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';

type ErrorHandlerParams = {
  error: {
    name?: string;
    message: string;
  };
};

function ErrorHandler(params: ErrorHandlerParams) {
  const { error } = params;

  const errorObj = React.useMemo(() => {
    const value: any = {};
    if (!error) {
      return value;
    }
    if (error.name === 'ValidationError') {
      return value;
    }
    value._UnknownError = error;
    return value;
  }, [error]);

  return (
    <Snackbar
      open={Boolean(errorObj._UnknownError)}
      message={errorObj._UnknownError?.message}
      anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
      sx={(theme) => ({ zIndex: theme.zIndex.appBar + 10 })}
    >
      <Alert variant="filled" severity="error">
        {`Something went wrong: ${errorObj._UnknownError?.message}`}
      </Alert>
    </Snackbar>
  );
}

export default ErrorHandler;
