import React from 'react';
import { Proposal } from "./getProposalModel"
import usePdfFn from './usePdfFn';
import Button from '@mui/material/Button';
import log from 'loglevel';

export type ViewElectronicProposalProps = {
  proposals: Proposal[],
}

export function ViewElectronicProposal(props: ViewElectronicProposalProps) {
  const { proposals } = props;
  const { getProposalSettings } = usePdfFn();

  const viewElectronicProposal = React.useCallback(async () => {
    const p1 = await getProposalSettings({ proposals });
    log.debug(p1);
    window.open(p1.acceptButtonUrl);
  }, [getProposalSettings, proposals]);

  return (
    <Button
      onClick={viewElectronicProposal}
      variant='outlined'
    >View Electronic Proposal</Button>
  )
}