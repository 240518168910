export type EmailTemplateParams = {
  message: string;
  acceptButtonUrl?: string;
}

function emailTemplate(params: EmailTemplateParams) {
  const { message, acceptButtonUrl } = params;

  const emailHtml = [];
  emailHtml.push(`<table border="0" cellspacing="0" width="100%">`);
  emailHtml.push(`
    <tr>
      <td width="600">
        <div style="margin: 2px">${message}</div>
      </td>
      <td>
      </td>
    </tr>
  `);
  if (acceptButtonUrl) {
    console.log('=============== ACCEPT =============', acceptButtonUrl);
    emailHtml.push(`
    <tr>
      <td width="600">
        <!-- button template -->
        <table width="100%" cellspacing="0" cellpadding="0">
          <tr>
              <td>
                  <table cellspacing="0" cellpadding="0">
                      <tr>
                          <td style="border-radius: 2px;" bgcolor="#117A65">
                              <a 
                                href="${acceptButtonUrl}" 
                                target="_blank" 
                                style="
                                  padding: 8px 12px; 
                                  border: 1px solid #117A65;
                                  border-radius: 2px;
                                  font-family: Helvetica, Arial, sans-serif;
                                  font-size: 14px; 
                                  color: #ffffff;
                                  text-decoration: none; 
                                  display: inline-block;
                                "
                              >
                              Accept Now           
                              </a>
                          </td>
                      </tr>
                  </table>
              </td>
          </tr>
        </table>
      </td>
      <td>
      </td>
    </tr>
    `);
  }
  emailHtml.push(`</table>`);

  return emailHtml.join('\n');
}

export default emailTemplate;