import React from 'react';
import Quill, { QuillOptions } from 'quill';
import QuillDelta from 'quill-delta';
import Box from '@mui/material/Box';

import updateTemplate from '../../api/templateHelper';

import 'quill/dist/quill.snow.css';
import { Proposal } from '../../pages/Proposals/getProposalModel';

interface QuillEditorProps {
  quillRef?: React.MutableRefObject<any>;
  options?: QuillOptions;
  disabled?: Boolean;
  error?: Boolean;
  onInit?: (params: { instance: Quill }) => void;
  onChange?: (params?: { instance: Quill, content: any, oldContent: any, source: string }) => void;
}

export type DeltaTemplateParams = {
  delta: any,
  proposal: Proposal,
}

/**
 * Processes a templated delta
 * and returns a delta
 */
function deltaTemplate(params: DeltaTemplateParams) {
  const { delta, proposal } = params;
  const d = new QuillDelta(JSON.parse(JSON.stringify(delta)));
  d.forEach((op) => {
    if (typeof op.insert === 'string') {
      op.insert = updateTemplate({ template: op.insert, data: proposal });
    }
  });
  return d;
}

function deltaToHtml(delta: any) {
  var tmp = document.createElement('div');
  const quill = new Quill(tmp);
  quill.setContents(delta);
  const html = quill.root.innerHTML;
  tmp.remove();
  return html;
}

function QuillEditor(props: QuillEditorProps) {
  const { options, disabled = false, onChange, onInit, error } = props;
  let { quillRef: editor } = props;
  const defEditor = React.useRef<any>();
  if (!editor) editor = defEditor;

  const ref = React.useRef<any>(null);
  React.useEffect(() => {
    if (
      ref.current &&
      editor &&
      (!editor.current || !editor.current.instance)
    ) {
      if (!editor.current) editor.current = {};
      const instance = new Quill(ref.current, {
        theme: 'snow',
        ...options,
      });
      editor.current.instance = instance;

      editor.current.instance.on('text-change', (newD: any, oldD: any, src: string) => {
        if (typeof onChange === 'function') {
          onChange({
            instance: instance,
            content: newD,
            oldContent: oldD,
            source: src,
          });
        }
      });

      if (typeof onInit === 'function') {
        onInit({ instance });
      }
    }
  }, [editor, options, onChange, onInit]);

  const borderStyle = React.useMemo(() => error ? '1px solid red' : undefined, [error]);

  React.useEffect(() => {
    if (editor?.current?.instance) {
      editor.current.instance.enable(!disabled);
    }
  }, [editor, disabled]);

  const onClick = () => {
    if (!editor) return;
    if (editor.current) editor.current.instance.focus();
  };

  const sx = {
    width: '100%',
    minHeight: '20vh',
  };

  return (
    <>
      <Box sx={{ border: borderStyle }}>
        <Box ref={ref} sx={sx} onClick={onClick} />
      </Box>
    </>
  );
}

export default QuillEditor;
export { deltaToHtml, deltaTemplate };
export type { QuillEditorProps };
